import React, { useState, useEffect } from "react"
import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import NavBar from "../components/NavBar"
import Metatags from "../components/metatags"
import Footer from "../components/Footer.es"

const IndexPage = ({ location, data }) => {
  const [mobile, setMobile] = useState(false)

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1024) {
        setMobile(false)
      } else {
        setMobile(true)
      }
    }
    if (typeof window !== "undefined") {
      handleResize()
      window.addEventListener("resize", () => {
        handleResize()
      })
    }
  }, [])
  return (
    <>
      <Metatags
        title="Multilógica"
        description="Proveedor de maquinaria para colchones"
        url="https://newsite.multilogica.ind.br/es/"
        image="https://newsite.multilogica.ind.br/Multilogica-icon.png"
        keywords="colchón, máquina de coser, mammut, beckmann sew"
        language="es"
      />
      <NavBar location={location["pathname"]} />
      <div className="w-full">
        {/* <GatsbyImage
          image={getImage(data.bannerTop)}
          backgroundColor={false}
          alt="máquinas de colchones"
        /> */}
        {mobile ? (
          <GatsbyImage
            image={getImage(data.elektroteksMobile)}
            backgroundColor={false}
            alt="elektroteks-acquires-mammut"
          />
        ) : (
          <GatsbyImage
            image={getImage(data.elektroteks)}
            backgroundColor={false}
            alt="elektroteks-acquires-mammut"
          />
        )}
        <div className="h-8" />
        <div className=" text-center">
          <Link to="elektroteks-acquires-mammut">
            <button className="text-mi-yellow text-xl bg-mi-blue py-4 px-8 rounded-full hover:bg-mi-light-blue">
              Más informaciones
            </button>
          </Link>
        </div>
        <div className="h-24" />
        <div className="max-w-2xl mx-2 md:mx-auto text-center">
          <div className="text-mi-blue text-3xl font-semibold">
            Proveedor de maquinaria para colchones
          </div>
          <div className="h-2" />
          <div className="text-mi-blue text-xl">
            Equipos de alta calidad y desempeño que se combinan con experiencia
            y excelencia en el servicio.
          </div>
        </div>
        <div className="h-24" />
      </div>
      <div className="bg-mi-blue">
        <div className="container mx-auto">
          <div className="h-24" />
          <div className="max-w-xl mx-auto text-center">
            <div className="text-white text-3xl font-semibold">
              Nuestras actividades
            </div>
          </div>
          <div className="h-24" />
          <div className="grid grid-cols-1 md:grid-cols-12">
            <div className="col-span-1" />
            <div className="col-span-10 grid grid-cols-1 md:grid-cols-2 gap-x-12 gap-y-12 mx-auto">
              <div className=" mx-auto text-center max-w-sm ">
                <div className="mx-8">
                  <GatsbyImage
                    image={getImage(data.iconMatress)}
                    backgroundColor={false}
                    alt="Suministro de máquinas para colchones"
                  />
                  <div className="h-2" />
                  <div className="text-white text-2xl">
                    Suministro de máquinas para colchones
                  </div>
                </div>
              </div>
              <div className=" mx-auto text-center max-w-sm ">
                <div className="mx-8">
                  <GatsbyImage
                    image={getImage(data.iconMaintenance)}
                    backgroundColor={false}
                    alt="Venta, instalación y reparación de máquinas"
                  />
                  <div className="h-2" />
                  <div className="text-white text-2xl">
                    Venta, instalación y reparación de máquinas
                  </div>
                </div>
              </div>
              <div className=" mx-auto text-center max-w-sm ">
                <div className="mx-8">
                  <GatsbyImage
                    image={getImage(data.iconTraining)}
                    backgroundColor={false}
                    alt="Capacitación de los operadores"
                  />
                  <div className="h-2" />
                  <div className="text-white text-2xl">
                    Capacitación de los operadores
                  </div>
                </div>
              </div>
              <div className=" mx-auto text-center max-w-sm ">
                <div className="mx-6">
                  <GatsbyImage
                    image={getImage(data.iconDiagnosis)}
                    backgroundColor={false}
                    alt="Diagnóstico, identificación y venta de repuestos"
                  />
                  <div className="h-2" />
                  <div className="text-white text-2xl">
                    Diagnóstico, identificación y venta de repuestos
                  </div>
                </div>
              </div>
              <div className=" mx-auto text-center max-w-sm ">
                <div className="mx-6">
                  <GatsbyImage
                    image={getImage(data.iconCustom)}
                    backgroundColor={false}
                    alt="Soluciones personalizadas y adecuación de equipos"
                  />
                  <div className="h-2" />
                  <div className="text-white text-2xl">
                    Soluciones personalizadas y adecuación de equipos
                  </div>
                </div>
              </div>
              <div className=" mx-auto text-center max-w-sm ">
                <div className="mx-8">
                  <GatsbyImage
                    image={getImage(data.iconPatterns)}
                    backgroundColor={false}
                    alt="Desarrollo de patrones"
                  />
                  <div className="h-2" />
                  <div className="text-white text-2xl">
                    Desarrollo de patrones
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="h-24" />
        </div>
      </div>
      <div className="w-full">
        <div className="h-24" />
        <div className="max-w-2xl mx-2 md:mx-auto text-center">
          <div className="text-mi-blue text-3xl font-semibold">
            Representante en Brasil para las máquinas:
          </div>
        </div>
        <div className="h-24" />
        <div className="grid grid-cols-1 md:grid-cols-12 mx-2">
          <div className="col-span-1" />
          <div className="col-span-10 grid grid-cols-1 gap-x-8 gap-y-12 mx-auto">
            <div className=" mx-auto ">
              <div className="grid grid-cols-1 md:grid-cols-12">
                <div className="col-span-3">
                  <GatsbyImage
                    image={getImage(data.logoMammut)}
                    backgroundColor={false}
                    alt="Mammut"
                    className="mr-10"
                  />
                </div>
                <div className=" text-xl col-span-8">
                  <p>
                    En los últimos 140 años la Empresa Stutznäcker ha sido
                    especializada en la fabricación de máquinas de colchones de
                    alto rendimiento. Debido a esta confiabilidad y alto
                    rendimiento las máquinas MAMMUT se utilizan en un incontable
                    número de fábricas y particularmente en empresas en que el
                    cumplimiento de plazos de entrega y la calidad del producto
                    final tienen la más alta prioridad.
                  </p>
                </div>
                <div className="col-span-1 place-self-end">
                  <GatsbyImage
                    image={getImage(data.madeInGermany)}
                    backgroundColor={false}
                    alt="Made in Germany"
                  />
                </div>
              </div>
              <div className="h-8" />
              <div className="grid grid-cols-1 md:grid-cols-12">
                <div className="col-span-3">
                  <GatsbyImage
                    image={getImage(data.logoBeckmann)}
                    backgroundColor={false}
                    alt="Beckmann"
                  />
                </div>
                <div className=" text-xl col-span-8">
                  <p>
                    Desde 1990 produce máquinas para la producción de colchones
                    y bandas para colchones. Compromiso con normas de calidad,
                    la entrega absoluta de cada uno de sus colaboradores, el
                    servicio impecable así como el cumplimiento de los plazos de
                    entrega ha hecho que los clientes la valoren a nivel
                    mundial.
                  </p>
                </div>
                <div className="col-span-1 place-self-end">
                  <GatsbyImage
                    image={getImage(data.madeInGermany)}
                    backgroundColor={false}
                    alt="Made in Germany"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="h-24" />
        <div className="bg-mi-gray">
          <div className="h-10" />
          <div className="max-w-xl mx-auto text-center">
            <div className="text-mi-blue text-2xl">
              Entra en contacto con nuestro equipo para obtener más información.
            </div>
            <div className="h-2" />
            <Link to="contacto">
              <button className="text-mi-yellow text-xl bg-mi-blue py-4 px-8 rounded-full hover:bg-mi-light-blue">
                Entrar en contacto
              </button>
            </Link>
          </div>
          <div className="h-10" />
        </div>
        <div className="h-24" />
        <GatsbyImage
          image={getImage(data.bannerBottom)}
          backgroundColor={false}
          alt="máquinas de colchones"
        />
      </div>
      <Footer />
    </>
  )
}
export default IndexPage

export const indexPT = graphql`
  query {
    iconMatress: file(relativePath: { eq: "icons/Icone_Multi_Colchao.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 80)
      }
    }
    iconPatterns: file(relativePath: { eq: "icons/Icone_Multi_Desenhos.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 80)
      }
    }
    iconDiagnosis: file(
      relativePath: { eq: "icons/Icone_Multi_Diagnostico.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 80)
      }
    }
    iconMaintenance: file(
      relativePath: { eq: "icons/Icone_Multi_Manutencao.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 80)
      }
    }
    iconCustom: file(
      relativePath: { eq: "icons/Icone_Multi_Personalizado.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 80)
      }
    }
    iconTraining: file(
      relativePath: { eq: "icons/Icone_Multi_Treinamento.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 80)
      }
    }
    logoMammut: file(relativePath: { eq: "logos/mammut.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 320)
      }
    }
    logoBeckmann: file(relativePath: { eq: "logos/beckmann.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 198)
      }
    }
    madeInGermany: file(relativePath: { eq: "logos/made-in-germany.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 80)
      }
    }
    bannerTop: file(relativePath: { eq: "banners/Banner_Home_2.jpg" }) {
      childImageSharp {
        gatsbyImageData(
          quality: 80
          layout: CONSTRAINED
          width: 1920
          height: 550
        )
      }
    }
    elektroteks: file(
      relativePath: { eq: "banners/Banner_Elektroteks_news_esp.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          quality: 80
          layout: CONSTRAINED
          width: 1920
          height: 550
        )
      }
    }
    elektroteksMobile: file(
      relativePath: { eq: "banners/Banner_Elektroteks_news_Mobile_esp.jpg" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 1080)
      }
    }
    bannerBottom: file(relativePath: { eq: "banners/Banner_2_home.jpg" }) {
      childImageSharp {
        gatsbyImageData(quality: 80, layout: CONSTRAINED, width: 1920)
      }
    }
  }
`
